<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    option: string;
    group?: string;
    selected?: boolean;
    selectable?: boolean;
    valid?: boolean;
    error?: string;
  }>(),
  {
    group: "check",
    selectable: true,
    selected: true,
    valid: true,
    error: "",
  }
);

const emits = defineEmits<{
  (e: "select"): void;
}>();

const select = () => {
  if (props.selectable) {
    emits("select");
  }
};
</script>

<template>
  <input
    :id="`${group}-${option}`"
    type="checkbox"
    class="-z-1 absolute opacity-0"
    :name="group"
    :value="option"    
    @click="select"
  />
  <div
    v-bind="$attrs"
    :class="{
      'border-2 border-blue-900': selected,
      'border-gray-300': !selected,
      'border-red-700': !valid,
      'border-maas-border-grey-normal': valid,
    }"
    class="bg-maas-background-default flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-md border"
  >
    <svg v-if="selected" width="15" height="12">
      <path
        d="M5.00003 8.586L1.70703 5.293L0.29303 6.707L5.00003 11.414L14.707 1.707L13.293 0.292999L5.00003 8.586Z"
        class="fill-maas-primary"
      />
    </svg>
  </div>
</template>
